<template>
  <div class="banner-container" ref="container">
    <img
      class="bg"
      src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203141805152971834.gif"
      alt=""
    />
    <div
      class="active-logo"
      :style="{ marginTop: isArrangePage ? '0' : '-45px' }"
    >
      <!-- <img
        src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104152218310739777.png"
        alt=""
      /> -->
      <div class="cloud"></div>
    </div>
    <img
      class="logo"
      src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202203091835178896735.png"
      alt=""
    />
    <canvas id="canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.active) {
      this.$nextTick(() => {
        this.initCanvas();
      });
    }
  },
  computed: {
    isArrangePage() {
      return this.$route.path.includes("arrangeDay");
    },
  },
  methods: {
    initCanvas() {},
  },
};
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  background: #ccc;
  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .logo {
    position: absolute;
    left: 5px;
    top: 5px;
    height: 37px;
  }
  .active-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      animation: act 15s ease infinite;
      z-index: 2;
      height: 30px;
    }
    // img:nth-of-type(1){
    //   height: 34px;
    // }
    // img:nth-of-type(2){
    //   height: 13px;
    //   // animation-delay: 3s;
    // }
    @keyframes act {
      10% {
        transform: translate(20px, 5px);
      }
      20% {
        transform: translate(1px, 0px);
      }
      40% {
        transform: translate(0px, 10px);
      }
      60% {
        transform: translate(20px, 10px);
      }
      70% {
        transform: translate(-5px, 8px);
      }
      80% {
        transform: translate(5px, -2px);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .cloud {
      position: absolute;
      height: 50px;
      width: 200px;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(40px);
      z-index: 1;
      animation: fade 5s linear infinite;
    }
    @keyframes fade {
      50% {
        transform: translate(2px, -1px);
        background: rgba(255, 255, 255, 0.8);
      }
      100% {
        transform: translate(0, 0);
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  #canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0.3;
  }
}
</style>
